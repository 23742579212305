import "./WinLost.scss";

import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Image,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import { useEffect, useMemo, useState } from "react";

import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Link } from "react-router-dom";
import { apis } from "../../../apis";

import { DownOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";

import { isArray } from "lodash";
import { CustomDateRangePicker } from "../../../common/components/date-range-picker";
import { getCurrencyList } from "../../../common/helpers/utils";
import { paths } from "../../../routes/path";
const WinLost = () => {
  const { Option } = Select;
  const { t } = useTranslation();
  const columns = [
    {
      title: t("content.win/Lost.col.username"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("content.win/Lost.col.turnover"),
      dataIndex: "turnover",
      key: "turnover",
    },

    {
      title: t("content.win/Lost.col.netTurnover"),
      dataIndex: "netTurnover",
      key: "netTurnover",
    },

    {
      title: t("content.win/Lost.col.currency"),
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: t("content.win/Lost.col.betCount"),
      dataIndex: "betCount",
      key: "betCount",
    },
    {
      title: t("content.win/Lost.col.winLost"),
      dataIndex: "winLost",
      key: "winLost",
    },
    {
      title: t("content.win/Lost.col.commission"),
      dataIndex: "commission",
      key: "commission",
    },
    {
      title: t("content.win/Lost.col.memberTotal"),
      dataIndex: "memberTotal",
      key: "memberTotal",
    },
    {
      title: t("content.win/Lost.col.company"),
      dataIndex: "company",
      key: "company",
    },
  ];

  const columnsCredit = [
    {
      title: t("content.win/Lost.col.username"),
      dataIndex: "username",
      key: "username",
      render: (value: any, record: any) => {
        return (
          <div className="flex align-center">
            <Link
              className="bold"
              to={paths.USER_DETAIL.replace(":id", record.username)}
            >
              {value}
            </Link>
          </div>
        );
      },
    },
    {
      title: t("content.win/Lost.col.turnover"),
      dataIndex: "turnover",
      key: "turnover",
    },

    {
      title: t("content.win/Lost.col.netTurnover"),
      dataIndex: "netTurnover",
      key: "netTurnover",
    },

    {
      title: t("content.win/Lost.col.currency"),
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: t("content.win/Lost.col.betCount"),
      dataIndex: "betCount",
      key: "betCount",
    },
    {
      title: t("content.win/Lost.col.grossCommission"),
      dataIndex: "grossCommission",
      key: "grossCommission",
    },
    {
      title: t("content.win/Lost.col.memberWinLost"),
      dataIndex: "memberWinLost",
      key: "memberWinLost",
    },
    {
      title: t("content.win/Lost.col.memberCommission"),
      dataIndex: "memberCommission",
      key: "memberCommission",
    },
    {
      title: t("content.win/Lost.col.memberTotal"),
      dataIndex: "memberTotal",
      key: "memberTotal",
    },
    {
      title: t("content.win/Lost.col.associateWinLost"),
      dataIndex: "associateWinLost",
      key: "associateWinLost",
    },
    {
      title: t("content.win/Lost.col.associateCommission"),
      dataIndex: "associateCommission",
      key: "associateCommission",
    },
    {
      title: t("content.win/Lost.col.associateTotal"),
      dataIndex: "associateTotal",
      key: "associateTotal",
    },
    {
      title: t("content.win/Lost.col.maWinLost"),
      dataIndex: "maWinLost",
      key: "maWinLost",
    },
    {
      title: t("content.win/Lost.col.maCommission"),
      dataIndex: "maCommission",
      key: "maCommission",
    },
    {
      title: t("content.win/Lost.col.maTotal"),
      dataIndex: "maTotal",
      key: "maTotal",
    },
    {
      title: t("content.win/Lost.col.smaWinLost"),
      dataIndex: "smaWinLost",
      key: "smaWinLost",
    },
    {
      title: t("content.win/Lost.col.smaCommission"),
      dataIndex: "smaCommission",
      key: "smaCommission",
    },
    {
      title: t("content.win/Lost.col.smaTotal"),
      dataIndex: "smaTotal",
      key: "smaTotal",
    },

    {
      title: t("content.win/Lost.col.ssmaWinLost"),
      dataIndex: "ssmaWinLost",
      key: "ssmaWinLost",
    },
    {
      title: t("content.win/Lost.col.ssmaCommission"),
      dataIndex: "ssmaCommission",
      key: "ssmaCommission",
    },
    {
      title: t("content.win/Lost.col.ssmaTotal"),
      dataIndex: "ssmaTotal",
      key: "ssmaTotal",
    },

    {
      title: t("content.win/Lost.col.shWinLost"),
      dataIndex: "shWinLost",
      key: "shWinLost",
    },
    {
      title: t("content.win/Lost.col.shCommission"),
      dataIndex: "shCommission",
      key: "shCommission",
    },
    {
      title: t("content.win/Lost.col.shTotal"),
      dataIndex: "shTotal",
      key: "shTotal",
    },

    {
      title: t("content.win/Lost.col.company"),
      dataIndex: "company",
      key: "company",
    },
  ];

  const tabs = [t("content.win/Lost.credit"), t("content.win/Lost.cash")];

  const [username, setUsername] = useState("");
  const [currency, setCurrency] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [voidBets, setVoidBets] = useState();
  const [decimalPointNumbers, setDecimalPointNumbers] = useState();
  const [valuesStake, setValuesStake] = useState(false);

  const [showData, setShowData] = useState([0]);
  const [loadings, setLoadings] = useState(false);
  const [activeTab, setActiveTab] = useState(t("content.win/Lost.cash"));
  const [checkColumnCash, setCheckColumnCash] = useState(() =>
    columns.map((item) => item.key)
  );
  const [checkColumnCredit, setCheckColumnCredit] = useState(() =>
    columnsCredit.map((item) => item.key)
  );
  const [gameType, setGameType] = useState([]);
  const [dataGameCash, setDataGameCash] = useState<any[]>();

  const sumCash: any = useMemo(
    () =>
      dataGameCash?.reduce(
        (acc, curr) => {
          acc.turnover += acc.turnover;
          acc.netTurnover += curr.total.netTurnover;
          acc.currency = curr.total.currency;
          acc.betCount += curr.total.betCount;
          acc.winLost += curr.total.winLost;
          acc.commission += curr.total.commission;
          acc.memberTotal += curr.total.memberTotal;
          acc.company += curr.total.company;

          return acc;
        },
        {
          turnover: 0,
          netTurnover: 0,
          currency: "",
          betCount: 0,
          WinLost: 0,
          commisson: 0,
          memberTotal: 0,
          company: 0,
        }
      ),
    [dataGameCash]
  );

  const handleCheckColum = (key: string) => {
    if (activeTab === t("content.win/Lost.cash")) {
      const isChecked = checkColumnCash.includes(key);
      if (isChecked) {
        const newState = checkColumnCash.filter((item) => item !== key);
        setCheckColumnCash(newState);
      } else {
        setCheckColumnCash([...checkColumnCash, key]);
      }
    } else {
      const isChecked = checkColumnCredit.includes(key);
      if (isChecked) {
        const newState = checkColumnCredit.filter((item) => item !== key);
        setCheckColumnCredit(newState);
      } else {
        setCheckColumnCredit([...checkColumnCash, key]);
      }
    }
  };

  const handleShowAllData = () => {
    if (showData.length !== dataGameCash?.length) {
      const showAll = dataGameCash?.map((item) => item.id);
      if (showAll) setShowData(showAll);
    } else {
      setShowData([]);
    }
  };
  const handleShowOneData = (id: any) => {
    const isChecked = showData.includes(id);

    console.log(showData);
    if (isChecked) {
      setShowData(showData.filter((item) => item !== id));
    } else {
      setShowData((prev) => [...prev, id]);
    }
  };

  const currentDate = new Date();
  const fromDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );

  const [filterDate, setFilterDate] = useState({
    fromDate: fromDate,
    toDate: currentDate,
  });

  const getWinLostList = async () => {
    setLoadings(true);
    const payload = {
      fromDate: filterDate.fromDate.toISOString(),
      toDate: filterDate.toDate.toISOString(),
      username: username ? username : undefined,
      transactionType,
      currency,
    };
    try {
      const res = await httpService.post(apis.report.winLost, payload);
      setDataGameCash(res?.data);
    } catch (error) {
      setDataGameCash([]);
      console.log("error", error);
    }
    setLoadings(false);
  };

  const getGameType = async () => {
    try {
      const res = await httpService.get(apis.gameAndProvider.gameType);
      setGameType(res?.data?.gameProviderArray);
    } catch (error) {
      setGameType([]);
      console.log("error", error);
    }
  };

  const [currencyList, setCurrencyList] = useState<any[]>([]);
  useEffect(() => {
    if (!currencyList?.length) {
      const fetchCurrencyList = async () => {
        try {
          const res = await getCurrencyList();
          setCurrencyList(res.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchCurrencyList();
    }
  }, []);

  useEffect(() => {
    getWinLostList();
    getGameType();
  }, [currency, transactionType, filterDate.fromDate, filterDate.toDate]);

  const onUsernameChange = (e: any) => {
    setUsername(e.target.value);
  };

  const onCurrencyChange = (value: any) => {
    setCurrency(value);
  };

  const onTransactionTypeChange = (value: any) => {
    setTransactionType(value);
  };

  const onChangeVoidBets = (e: any) => {
    setVoidBets(e.target.value);
  };

  const onChangeDecimalPointNumbers = (e: any) => {
    setDecimalPointNumbers(e.target.value);
  };

  const handleChangeStake = () => {
    setValuesStake(!valuesStake);
  };

  const handleClickTab = (tab: any) => {
    setActiveTab(tab);
  };

  const onClickApplyDate = (event: any, picker: any) => {
    const fromDate = moment(picker.startDate).toDate();
    const toDate = moment(picker.endDate).toDate();
    setFilterDate({
      ...filterDate,
      fromDate,
      toDate,
    });
  };

  const clearDate = () => {
    setFilterDate({
      ...filterDate,
      fromDate: fromDate,
      toDate: currentDate,
    });
  };

  const enterLoading = async () => {
    setLoadings(true);
    await getWinLostList();
    setLoadings(false);
  };

  const stakeSize = useMemo(() => {
    let size = 0;
    if (
      checkColumnCash.includes("turnover") &&
      checkColumnCash.includes("netTurnover")
    ) {
      return (size = 2);
    }
    if (
      checkColumnCash.includes("turnover") ||
      checkColumnCash.includes("netTurnover")
    ) {
      return (size = 1);
    }

    return size;
  }, [checkColumnCash]);
  return (
    <div>
      <div id="win-lost">
        <div className="filter-main">
          <div className="header">
            <div className="header-name">{t("content.win/Lost.title")}</div>
            <div className="header-export">
              <Button>
                <Image
                  width={20}
                  src="/excel_icon.png"
                  style={{ marginBottom: 4, paddingRight: 2 }}
                />
                <CSVLink
                  filename={"Expense_Table.csv"}
                  data={dataGameCash || []}
                  className="btn btn-primary"
                >
                  <span> {t("content.win/Lost.export")}</span>
                </CSVLink>
              </Button>
            </div>
          </div>
          <Row className="filter-bar">
            <Col className="date-range-transaction-history">
              <div>{t("content.win/Lost.statisticalDate")}</div>
              <CustomDateRangePicker
                onApply={onClickApplyDate}
                onCancel={clearDate}
                containerStyles={{
                  width: "216px",
                  padding: "3px 0px",
                  paddingLeft: "4px",
                  borderRadius: "6px",
                  border: "1px solid #d9d9d9",
                }}
                fromDate={filterDate.fromDate}
                toDate={filterDate.toDate}
                resultToString
                opens={"center"}
                readOnly
              />
            </Col>
            <Col>
              <div>{t("content.win/Lost.gameType")}</div>
              <Select
                defaultValue={t("content.win/Lost.all")}
                style={{ width: 170 }}
                onChange={onTransactionTypeChange}
              >
                <Option value="">{t("content.win/Lost.all")}</Option>
                {gameType?.map((item, index) => (
                  <Option value={item} key={index}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <div>{t("content.win/Lost.currency")}</div>
              <Select
                defaultValue={t("content.win/Lost.all")}
                style={{ width: 120 }}
                onChange={onCurrencyChange}
              >
                <Option value="">{t("content.win/Lost.all")}</Option>
                {currencyList?.map((item, index) => (
                  <Option value={item?.currency} key={index}>
                    {item?.currency}
                  </Option>
                ))}
              </Select>
            </Col>

            {activeTab === t("content.win/Lost.cash") && (
              <Col>
                <div>{t("content.win/Lost.username")}</div>
                <Input
                  placeholder={t("content.win/Lost.username")}
                  onChange={onUsernameChange}
                  style={{ width: 150 }}
                />
              </Col>
            )}

            <Col>
              <div>{t("content.win/Lost.includeExcludeVoid.title")}</div>
              <Radio.Group
                className="flex align-center"
                defaultValue="ind"
                onChange={onChangeVoidBets}
                value={voidBets}
              >
                <Radio value="ind">
                  {t("content.win/Lost.includeExcludeVoid.type.indVoidBets")}
                </Radio>
                <Radio value="excl">
                  {t("content.win/Lost.includeExcludeVoid.type.exclVoidBets")}
                </Radio>
              </Radio.Group>
            </Col>
            <Col>
              <div>{t("content.win/Lost.decimalPointNumbers")}</div>
              <Radio.Group
                className="flex align-center"
                defaultValue={2}
                onChange={onChangeDecimalPointNumbers}
                value={decimalPointNumbers}
              >
                <Radio value={2}>2</Radio>
                <Radio value={3}>3</Radio>
                <Radio value={4}>4</Radio>
                <Radio value={5}>5</Radio>
                <Radio value={6}>6</Radio>
              </Radio.Group>
            </Col>
            <Col>
              <Button
                className="submit-btn"
                type="primary"
                onClick={getWinLostList}
              >
                {loadings && (
                  <tr className="loading-btn" style={{ marginRight: 20 }}>
                    <Loading3QuartersOutlined className="loading-icon-btn" />
                  </tr>
                )}
                <span>{t("content.win/Lost.submit")}</span>
              </Button>
            </Col>
          </Row>
          <div>
            <span className="pending">01/02 Pending</span>
          </div>

          <div className="filter-table" style={{ padding: "4px" }}>
            <div className="type-money">
              {tabs.map((tab) => (
                <div
                  onClick={() => handleClickTab(tab)}
                  className={
                    activeTab === tab
                      ? "type-money__active"
                      : "type-money__not-active"
                  }
                  key={tab}
                >
                  {tab}
                </div>
              ))}
            </div>

            <Dropdown
              dropdownRender={() => (
                <div className="filter-table__dropdown">
                  {activeTab === t("content.win/Lost.cash") &&
                    columns.map((item: any, index: any) => (
                      <div
                        onClick={() => handleCheckColum(item.key)}
                        className="dropdown_item"
                        style={{
                          display: "flex",
                          padding: "6px 10px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Checkbox
                          style={{ marginRight: 10 }}
                          checked={checkColumnCash.includes(item.key)}
                        />
                        <div key={index}>{item?.title}</div>
                      </div>
                    ))}
                  {activeTab === t("content.win/Lost.credit") &&
                    columnsCredit.map((item, index) => (
                      <div
                        onClick={() => handleCheckColum(item.key)}
                        style={{
                          display: "flex",
                          padding: "6px 10px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Checkbox
                          style={{ marginRight: 10 }}
                          checked={checkColumnCredit.includes(item.key)}
                        />
                        <div key={index}>{item?.title}</div>
                      </div>
                    ))}
                </div>
              )}
            >
              <a onClick={(e) => e.preventDefault()}>
                <div className="bars">
                  <img width={16} src="/bars_icon.svg"></img>
                </div>
              </a>
            </Dropdown>
          </div>
        </div>
        <div className="table-main">
          <table>
            <thead>
              <tr>
                <td colSpan={2}></td>
                {stakeSize > 0 && (
                  <td colSpan={stakeSize}>
                    <div className="stake-btn btn" onClick={handleChangeStake}>
                      {valuesStake
                        ? t("content.win/Lost.col.byStake")
                        : t("content.win/Lost.col.byActualStake")}
                    </div>
                  </td>
                )}

                <td
                  colSpan={
                    activeTab === t("content.win/Lost.credit")
                      ? columnsCredit.length
                      : columns.length
                  }
                ></td>
              </tr>

              <tr>
                <td>
                  <div
                    className="plus-btn btn"
                    onClick={() => handleShowAllData()}
                  >
                    {showData?.length > 0 ? "-" : "+"}
                  </div>
                </td>

                {activeTab === t("content.win/Lost.cash") &&
                  columns.map((item, index) => {
                    const isChecked = checkColumnCash.includes(item.key);
                    if (isChecked) {
                      return <td key={index}>{item?.title}</td>;
                    }
                  })}
                {activeTab === t("content.win/Lost.credit") &&
                  columnsCredit.map((item, index) => {
                    const isChecked = checkColumnCredit.includes(item.key);
                    if (isChecked) {
                      return <td key={index}>{item?.title}</td>;
                    }
                  })}
              </tr>
            </thead>

            {activeTab === t("content.win/Lost.cash") && (
              <>
                {dataGameCash?.map((items: any, index) => (
                  <tbody key={index}>
                    <tr>
                      <td
                        className="name-game"
                        onClick={() => handleShowOneData(items.id)}
                        colSpan={columns.length + 1}
                      >
                        <DownOutlined
                          className={
                            showData.includes(items.id)
                              ? "downout-icon"
                              : "downout-icon active"
                          }
                        />
                        <span>{items.name}</span>
                      </td>
                    </tr>

                    {showData.includes(items.id) &&
                      items?.data?.map((item: any, index: any) => (
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            <span>{index + 1}</span>
                          </td>

                          {columns.map((col: any) => {
                            const isChecked = checkColumnCash.includes(col.key);

                            if (isChecked) {
                              if (item[col.key] < 0) {
                                return (
                                  <td
                                    style={{
                                      color: "red",
                                      textAlign: "right",
                                    }}
                                  >
                                    <span>{item[col.key]}</span>
                                  </td>
                                );
                              }
                              if (col.key === "username") {
                                return (
                                  <td>
                                    <Link
                                      to={paths.USER_DETAIL.replace(
                                        ":id",
                                        item[col.key]
                                      )}
                                    >
                                      <span>{item[col.key]}</span>
                                    </Link>
                                  </td>
                                );
                              }
                              if (
                                col.key === "betCount" ||
                                col.key === "currency"
                              ) {
                                return (
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <span>{item[col.key]}</span>
                                  </td>
                                );
                              }

                              return (
                                <td
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  <span>{item[col.key]}</span>
                                </td>
                              );
                            }
                          })}
                        </tr>
                      ))}

                    <tr key={index} className="total-table">
                      <td
                        colSpan={2}
                        style={{
                          textAlign: "right",
                          fontWeight: 700,
                        }}
                      >
                        {t("content.win/Lost.total")}
                      </td>

                      <>
                        {items?.total &&
                          Object.keys(items?.total).map((key) => {
                            const isChecked = checkColumnCash.includes(key);
                            if (isChecked) {
                              if (key === "betCount" || key === "currency") {
                                return (
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <span>{items.total[key]}</span>
                                  </td>
                                );
                              }
                              return (
                                <td
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  <span>{items.total[key]}</span>
                                </td>
                              );
                            }
                          })}
                      </>
                    </tr>
                  </tbody>
                ))}
              </>
            )}

            {activeTab === t("content.win/Lost.credit") && (
              <div style={{ padding: 16, width: "100px" }}>
                <p style={{ color: "#888" }}>{t("content.win/Lost.noData")}</p>
              </div>
            )}

            <tfoot>
              {activeTab === t("content.win/Lost.cash") &&
                isArray(dataGameCash?.length) && (
                  <>
                    <tr className="grand-total-table">
                      <td
                        colSpan={2}
                        style={{
                          textAlign: "right",
                          fontWeight: 700,
                        }}
                      >
                        Grand Total
                      </td>

                      {sumCash &&
                        Object.keys(sumCash).map((key: string) => {
                          const isChecked = checkColumnCash.includes(key);
                          if (isChecked) {
                            if (key === "betCount" || key === "currency") {
                              return (
                                <td style={{ textAlign: "center" }}>
                                  <span>{sumCash[key]}</span>
                                </td>
                              );
                            }
                            return (
                              <td
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <span>{sumCash[key]}</span>
                              </td>
                            );
                          }
                        })}
                    </tr>
                  </>
                )}
            </tfoot>
            {loadings && (
              <tr className="loading-mask-table">
                <div className="loading-icon-table">
                  Loading{" "}
                  <Loading3QuartersOutlined
                    className="loading-icon"
                    style={{ fontSize: 28, paddingLeft: 8 }}
                  />
                </div>
              </tr>
            )}
            {!dataGameCash?.length &&
              activeTab === t("content.win/Lost.cash") && (
                <div className="no-data">
                  <p style={{ color: "#888" }}>
                    {t("content.win/Lost.noData")}
                  </p>
                </div>
              )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default WinLost;
