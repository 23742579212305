import { CrownOutlined, TransactionOutlined } from "@ant-design/icons";
import { Image, Layout, Select, Space } from "antd";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import flagVn from "../../../../../public/vietnam-flag-icon.png"
import { DashboardOutlined, MenuOutlined, QuestionCircleFilled } from "@ant-design/icons";
import { Col, Input, Popover } from "antd";
import { useEffect } from "react";
import { IProfile } from "../../../../interfaces/User";
import { paths } from "../../../../routes/path";
import { authActions } from "../../../../store/slices/auth";
import { setLanguage } from "../../../../store/slices/language";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../constants/local-storage-keys";
import "./LightLayout.scss";
// import flagVn from "../../../../../public/vietnam-flag-icon.png"
import moment from "moment";
import Marquee from "react-fast-marquee";
import "./LightLayout.scss";
// import flagVn from "/vietnam-flag-icon.png"

export interface ILightLayout {
    children: React.ReactNode;
    profile?: IProfile;
}

const LightLayout: React.FC<ILightLayout> = ({ children, profile }) => {
    const [formattedTime, setFormattedTime] = useState("Loading...");

    const { t } = useTranslation();
    const { Header, Content, Sider } = Layout;
    const lang = useSelector((state: any) => state.language.language);

    const flag = [
        {
            key: "vi",
            value: "/vietnam-flag-icon.svg",
        },
        {
            key: "en",
            value: "/united-kingdom-flag-icon.svg",
        },
    ];
    const menuItems = [
        {
            label: t("content.sidebar.dashboard"),
            icon: React.createElement(DashboardOutlined),
            key: "/",
            stt: 1,
        },
        {
            label: t("content.sidebar.report.title"),
            icon: React.createElement(MenuOutlined),
            key: "/report",
            stt: 2,
            children: [
                {
                    key: "/report/win-lost",
                    label: t("content.sidebar.report.winLost"),
                },
                {
                    key: "/report/revenue-statement",
                    label: t("content.sidebar.report.revenueStatement"),
                },
                {
                    key: "/report/betting-record",
                    label: t("content.sidebar.report.bettingRecord"),
                },
                {
                    key: "/report/player-summary",
                    label: t("content.sidebar.report.playerSummary"),
                },
                {
                    key: "/report/win-lost-by-player",
                    label: t("content.sidebar.report.winLostByPlayer"),
                },
                {
                    key: "/report/win-lost-by-player-page",
                    label: t("content.sidebar.report.winLostByPlayerPage"),
                },
                {
                    key: "/report/daily",
                    label: t("content.sidebar.report.daily"),
                },
            ],
        },
        {
            label: t("content.sidebar.security.title"),
            icon: React.createElement(MenuOutlined),
            key: "/security",
            stt: 3,
            children: [
                {
                    key: "/security/change-password",
                    label: t("content.sidebar.security.changePassword"),
                },
                {
                    key: "/security/change-login-name",
                    label: t("content.sidebar.security.changeLoginName"),
                },
            ],
        },
        {
            label: t("content.sidebar.subAccount.title"),
            icon: React.createElement(MenuOutlined),
            key: "/sub-account",
            stt: 4,
            children: [
                {
                    key: "/member/sub-account-list",
                    label: t("content.sidebar.subAccount.subAccountList"),
                },
                {
                    key: "/resource/manage",
                    label: t("content.sidebar.subAccount.permission"),
                },
            ],
        },

        {
            label: t("content.sidebar.txn.title"),
            icon: React.createElement(TransactionOutlined),
            key: "/transactions",
            stt: 6,
            children: [
                {
                    key: "/transactions/deposit",
                    label: t("content.sidebar.txn.deposit"),
                },
                {
                    key: "/transactions/withdraw",
                    label: t("content.sidebar.txn.withdraw"),
                },
                {
                    key: "/transactions/player-bank-options",
                    label: t("content.sidebar.txn.playerBankOptions"),
                },
                {
                    key: "/transactions/transactions",
                    label: t("content.sidebar.txn.transactions"),
                },
                {
                    key: "/transactions/balance-history",
                    label: t("content.sidebar.txn.balanceHistory"),
                },
            ],
        },
        {
            label: t("content.sidebar.gameAndProvider.title"),
            icon: React.createElement(MenuOutlined),
            key: "/game-and-provider",
            stt: 7,
            children: [
                {
                    key: "/game-and-provider/game-provider",
                    label: t("content.sidebar.gameAndProvider.gameProvider"),
                },
                {
                    key: "/game-and-provider/game-list",
                    label: t("content.sidebar.gameAndProvider.gameList"),
                },
            ],
        },
        {
            label: t("content.sidebar.webAdmin.title"),
            icon: React.createElement(MenuOutlined),
            key: "/web-admin",
            stt: 8,
            children: [
                {
                    key: "/web-admin/customize-theme",
                    label: t("content.sidebar.webAdmin.customizeTheme"),
                },
                {
                    key: "/web-admin/admin-controls",
                    label: t("content.sidebar.webAdmin.adminControls"),
                },
            ],
        },
        {
            label: t("content.sidebar.company.title"),
            icon: React.createElement(MenuOutlined),
            key: "/company",
            stt: 9,
            children: [
                {
                    key: "/company/language",
                    label: t("content.sidebar.company.language"),
                },
                {
                    key: "/company/currency",
                    label: t("content.sidebar.company.currency"),
                },
            ],
        },
        {
            label: t("content.sidebar.paymentGateway.title"),
            icon: React.createElement(MenuOutlined),
            key: "/company",
            stt: 10,
            children: [
                {
                    key: "/payment-gateway/setting",
                    label: t("content.sidebar.paymentGateway.setting"),
                },
                {
                    key: "/payment-gateway/payment-transaction",
                    label: t("content.sidebar.paymentGateway.paymentTransaction"),
                },
                {
                    key: "/payment-gateway/manual-payment-transaction",
                    label: t("content.sidebar.paymentGateway.manualPaymentTransaction"),
                },
            ],
        },

        {
            label: t("content.promotions.title"),
            icon: React.createElement(TransactionOutlined),
            key: "/promotions",
            stt: 11,
            children: [
                {
                    key: "/promotions/create",
                    label: t("content.promotions.create.label"),
                },
                {
                    key: "/promotions/list",
                    label: t("content.promotions.list.title"),
                },
                {
                    key: "/promotions/application-verify",
                    label: t("content.promotions.applicationVerify.title"),
                },
                {
                    key: "/promotions/application-record",
                    label: t("content.promotions.applicationRecord.title"),
                },
                {
                    key: "/promotions/promotion-statistics",
                    label: t("content.promotions.promotionStatistics.title"),
                },
            ],
        },
    ];

    const parentKeys = menuItems.map((i) => i.key);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [currentRoute, setCurrentRoute] = useState<{
        selectedKey: string[] | never[];
        openKey: string[] | never[];
    }>({
        selectedKey: [],
        openKey: [],
    });

    const getOpenKey = (fullPath: string) => {
        return parentKeys.find((i) => fullPath.indexOf(i) > -1);
    };

    useLayoutEffect(() => {
        const openKey = getOpenKey(location.pathname);

        if (openKey) {
            setCurrentRoute({
                selectedKey: [location.pathname],
                openKey: [...new Set([...currentRoute.openKey, openKey])],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const onLogout = () => {
        window.localStorage.removeItem(ACCESS_TOKEN);
        window.localStorage.removeItem(REFRESH_TOKEN);
        dispatch(authActions.logout());
        navigate(paths.LOGIN);
    };

    const handleChangeLanguage = (value: string) => {
        dispatch(setLanguage(value));
    };

    const [username, setUsername] = useState("");

    const onChangeUsername = (e: any) => {
        setUsername(e.target.value);
    };
    const onClickSearchUsername = () => {
        if (username) {
            const path = paths.USER_DETAIL.replace(":id", username);
            navigate(path);
        }
    };

    const [activeList, setActiveList] = useState<any>([]);
    const [activeChild, setActiveChild] = useState("");

    const onClickCollapseMenu = (index: any) => {
        //Don't set active in dashboard
        if (index === 1) {
            navigate("/");
            return;
        }

        if (activeList.includes(index)) {
            const actives = activeList.filter((item: number) => item !== index);
            setActiveList(actives);
        } else {
            setActiveList([index]);
        }
    };

    const handleClickSidebar = (child: any) => {
        if (child === "/") {
            setActiveChild("dashboard");
            navigate("/");
        } else {
            setActiveChild(child.key);
        }

        if (typeof child !== "string") {
            navigate(child.key);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            const gmtMinusFourTime = moment().utcOffset("-0400").format("YYYY/MM/DD hh:mm:ss A");
            setFormattedTime(gmtMinusFourTime);
        }, 1000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="wrapper">
            <Layout className="wrapper-layout">
                {/* <Header className='header' style={{ backgroundColor: "#606f82" }}> */}
                <Header className="header" style={{ zIndex: 10 }}>
                    <div className="header-content">
                        <div className="logo">
                            <CrownOutlined />
                            <span> acctest_palyer</span>
                            <span style={{ marginLeft: 20 }}>{formattedTime} GMT-4</span>
                        </div>

                        <div>
                            <span>
                                <Space>
                                    {flag.map((item) => {
                                        if (item.key === lang) {
                                            return (
                                                <Image
                                                    width={20}
                                                    src={item.value}
                                                    key={item.key}
                                                    style={{ marginBottom: 4 }}
                                                    preview={false}
                                                />
                                            );
                                        }
                                    })}
                                </Space>
                                <Select
                                    defaultValue="en"
                                    onChange={handleChangeLanguage}
                                    options={[
                                        { value: "vi", label: "Việt Nam" },
                                        { value: "en", label: "English" },
                                    ]}
                                />
                            </span>

                            <span className="logout" onClick={onLogout}>
                                {t("logout")}
                            </span>
                        </div>
                    </div>
                </Header>
                <Layout className="content">
                    <Sider
                        width={212}
                        // style={{
                        //     overflowY: "auto",
                        //     height: "95.4vh",
                        //     position: "sticky",
                        //     top: 41,
                        //     left: 0,
                        // }}
                        theme="light"
                    >
                        {/* <Menu
                            // theme='dark'
                            // style={{ height: "100%", borderRight: 0, paddingTop: 16 }}

                            onSelect={onSelectMenuItem}
                            mode="inline"
                            selectedKeys={currentRoute.selectedKey}
                            openKeys={currentRoute.openKey}
                            onOpenChange={onOpenChange}
                            items={menuItems}
                            className='menu'
                        /> */}

                        <Col className="custom-sidebar">
                            <div className="custom-sidebar-top">
                                <Col className="security-msg">
                                    <Marquee style={{ overflow: "hidden" }} pauseOnHover speed={30}>
                                        <p style={{ marginLeft: 20 }}>{` Welcome to administration dashboard`} </p>
                                    </Marquee>
                                </Col>
                                <Col className="timezone">
                                    <div className="timezone-title">
                                        <Col>Time Zone</Col>
                                        <Col style={{ marginLeft: 10 }}>
                                            <Popover
                                                placement="bottomLeft"
                                                content={`'The selection of time zone only changes the displayed time,
                                                 such as player registration time, withdrawal time, etc. The system time zone is GMT-4, so any promotions and referral calculation and query conditions remain in GMT-4.'
                                                `}
                                                overlayStyle={{
                                                    width: "20vw",
                                                }}
                                            >
                                                <QuestionCircleFilled />
                                            </Popover>
                                        </Col>
                                    </div>
                                    <div>
                                        <Col>
                                            <Select
                                                className="time-select"
                                                defaultValue="GMT4"
                                                // onChange={handleChangeLanguage}
                                                options={[
                                                    { value: "GMT4", label: "GMT-4 (System-time)" },
                                                    { value: "GMT6", label: "GMT-6+5" },
                                                    { value: "GMT7", label: "GMT-7" },
                                                    { value: "GMT8", label: "GMT-8" },
                                                    { value: "GMT9", label: "GMT-9" },
                                                ]}
                                            />
                                        </Col>
                                        <Col>
                                            <Col className="search">
                                                <Select
                                                    className="account-select"
                                                    defaultValue="acc1"
                                                    // onChange={handleChangeLanguage}
                                                    options={[
                                                        { value: "acc1", label: "Account ID" },
                                                        { value: "acc2", label: "A/c Number" },
                                                    ]}
                                                    style={{ padding: 0 }}
                                                />
                                                <Input className="account-input" onChange={onChangeUsername} />
                                                <Col onClick={onClickSearchUsername}>
                                                    <div className="search-icon"></div>
                                                </Col>
                                            </Col>
                                        </Col>
                                    </div>
                                </Col>
                            </div>

                            <div className="custom-sidebar-menu">
                                <Col className="menu-list">
                                    {menuItems.map((item, index) => {
                                        return (
                                            <Col className="menu-item" key={index}>
                                                <Col
                                                    className={`collapse-menu ${
                                                        activeList.includes(item.stt) ? "active" : ""
                                                    }  `}
                                                    onClick={() => onClickCollapseMenu(item.stt)}
                                                >
                                                    {item.children?.length && (
                                                        <>
                                                            {activeList.includes(item.stt) ? (
                                                                <div className="collapse-menu-icon">
                                                                    <span>-</span>
                                                                </div>
                                                            ) : (
                                                                <div className="collapse-menu-icon">
                                                                    <span>+</span>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}

                                                    <div
                                                        className="menu-heading"
                                                        onClick={() => {
                                                            return handleClickSidebar(item.key);
                                                        }}
                                                    >
                                                        {item.label}
                                                    </div>
                                                </Col>
                                                {item.children?.length && (
                                                    <Col
                                                        className={`collapse-menu-item-panel ${
                                                            activeList.includes(item.stt) ? "active" : ""
                                                        }  `}
                                                    >
                                                        <ul className="menu-item-ul">
                                                            {item.children.map((child, index) => {
                                                                return (
                                                                    <li
                                                                        className={`menu-item-li ${
                                                                            child.key === activeChild ? "active" : ""
                                                                        }  `}
                                                                        key={index}
                                                                        onClick={() => handleClickSidebar(child)}
                                                                    >
                                                                        {child.label}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Col>
                                                )}
                                            </Col>
                                        );
                                    })}
                                </Col>
                            </div>
                        </Col>
                    </Sider>

                    <Layout>
                        <Content
                            style={{
                                padding: 24,
                                minHeight: "95vh",
                                background: "rgb(236, 236, 236)",
                            }}
                        >
                            {children}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </div>
    );
};

export default LightLayout;
