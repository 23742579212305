import { createSlice } from "@reduxjs/toolkit";

const permissionSlice = createSlice({
    name: "permission",
    initialState: {
        modules: [],
    },
    reducers: {
        setModule: (state, action) => {
            state.modules = action.payload;
        },
    },
});

export const { setModule } = permissionSlice.actions;

export default permissionSlice.reducer;
