import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Card, Col, Form, Input, Popconfirm, Row, Table, Typography } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { apis } from "../../../apis"
import { httpService } from "../../../apis/httpService"
import { EditableCell } from "../../../common/components/txn-table/EditableCell"
import { paths } from "../../../routes/path"
import "./List.scss"

interface Item {
    id: string
    key: string
    name: string
    code: string
}

const List = () => {
    const navigate = useNavigate()
    const [textSearch, setTextSearch] = useState("")
    const [editingKey, setEditingKey] = useState("")
    const [data, setData] = useState<Item[]>([])
    const [form] = Form.useForm()

    const isEditing = (record: Item) => record.id === editingKey

    const columns = [
        {
            title: "Tên",
            dataIndex: "name",
            key: "1",
            editable: true,
        },
        {
            title: "Ký hiệu",
            dataIndex: "code",
            key: "2",
            editable: true,
        },

        {
            title: <div className='flex-center'>Hành động</div>,
            key: "action",
            render: (item: any, record: Item) => {
                const editable = isEditing(record)
                return editable ? (
                    <span className='flex-center'>
                        <Typography.Link onClick={() => save(record.id)} style={{ marginRight: 8 }}>
                            Lưu
                        </Typography.Link>

                        <Typography.Link onClick={() => setEditingKey("")}>Huỷ</Typography.Link>
                    </span>
                ) : (
                    <div className='flex-center'>
                        <EditOutlined
                            disabled={editingKey !== ""}
                            onClick={() => edit(record)}
                            style={{ cursor: "pointer" }}
                        />
                        <Popconfirm
                            title='Bạn có chắc muốn xoá?'
                            onConfirm={() => deleteBank(record.id)}
                        >
                            <DeleteOutlined
                                style={{ marginLeft: 30, cursor: "pointer", color: "red" }}
                            />
                        </Popconfirm>
                    </div>
                )
            },
        },
    ]

    const edit = (record: Partial<Item>) => {
        if (record.id) {
            form.setFieldsValue({ name: "", code: "", ...record })
            setEditingKey(record.id)
        }
    }

    const deleteBank = (bankId: string) => {
        console.log("bankId", bankId)
    }

    const save = async (id: React.Key) => {
        try {
            const row = (await form.validateFields()) as Item

            const newData = [...data]
            const index = newData.findIndex((item) => id === item.id)
            if (index > -1) {
                const item = newData[index]
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                })
                setData(newData)
                setEditingKey("")
            } else {
                newData.push(row)
                setData(newData)
                setEditingKey("")
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo)
        }
    }

    const getListBanks = async () => {
        const res = await httpService.get(`${apis.bank.bank}?take=100&query=${textSearch}`)
        setData(res?.data?.result)
    }

    useEffect(() => {
        getListBanks()
    }, [textSearch])

    const handleSearch = (e: any) => {
        setTextSearch(e.target.value)
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType: "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        }
    })

    return (
        <div>
            <Row className='bank-header'>
                <Col span={12}>
                    <div className='col-inner'>
                        <Button type='primary' onClick={() => navigate(paths.ADD_BANK)}>
                            Thêm mới
                        </Button>
                    </div>
                </Col>

                <Col span={12}>
                    <div className='col-inner'>
                        <Input placeholder='Tìm kiếm' onChange={handleSearch} />
                    </div>
                </Col>
            </Row>
            <Card title='Danh sách ngân hàng' className='card-table'>
                <Form form={form} component={false}>
                    <Table
                        dataSource={data}
                        columns={mergedColumns}
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowClassName='editable-row'
                    />
                </Form>
            </Card>
        </div>
    )
}

export default List
