import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { httpService } from "../apis/httpService";
import { IStoreState } from "../interfaces/Store";
import { setModule } from "../store/slices/permission";

export const usePermission = () => {
    const dispatch = useDispatch();
    const appStates = useSelector((state: IStoreState) => state.auth);
    const { modules } = useSelector((state: any) => state.promotion);

    const fetchPermission = async () => {
        if (appStates.profile.id) {
            try {
                const res = await httpService.get(
                    "http://api.789lava.com/v1/admin/menu?roleId=002c1b25-1d4e-4f94-ae69-e96c8c43f491"
                    // `http://api.789lava.com/v1/admin/menu/user/${appStates.profile.id}`
                );
                console.log("res", res);
                dispatch(setModule(res.data.admin));
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        fetchPermission();
    }, [dispatch]);

    return { modules };
};
