import { PlusOutlined, UserAddOutlined } from "@ant-design/icons";
import type { CollapseProps } from "antd";
import { Checkbox, Collapse } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import ModalCreateSubAccount from "../../../common/components/modal-create-sub-account/ModalCreateSubAccount";
import { ModalNewGroupList } from "../../../common/components/modal-new-groupList";
import { usePermission } from "../../../hooks/usePermission";
import "./Manage.scss";

export const Manage = () => {
    const { t } = useTranslation();

    const items: CollapseProps["items"] = [
        {
            key: "1",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Search</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Search</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Withdraw Limit</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "2",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Dashboard</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Operational Dashboard</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "3",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Member</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Account List</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Account Balance</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Tag Setting</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Message</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "4",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Report</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Win/Lost</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Revenue Statements</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Betting Record</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Sports Betting Record</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Player Summary</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                    <div className="permission-child">
                        <span className="permission-child-title">Daily Report</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "5",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Transaction</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Deposit Verify</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "6",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Promotion</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Create Promotion</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "7",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Referral</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Referral List</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "8",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Game & Provider</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Provider List</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "9",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Website Setting</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">System Parameter</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "10",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Cash Agent</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">Agent Requirement</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
        {
            key: "11",
            label: (
                <>
                    <div className="permission-collapse">
                        <span className="permission-collapse-title">Risk Management</span>
                        <div>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    <div className="permission-child">
                        <span className="permission-child-title">IP/FP Audit</span>
                        <div className="permission-action">
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Create</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Read</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Update</Checkbox>
                            <Checkbox style={{ marginRight: "20px", fontSize: "12px" }}>Delete</Checkbox>
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const [activeIndex, setActiveIndex] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const showModalCreate = (record?: any) => {
        //  setDataEdit(record)
        setIsModalCreateOpen(true);
    };
    const handleItemClick = (index: any) => {
        setActiveIndex(index);
    };
    const onClickNewGroupList = () => {
        setIsModalOpen(true);
    };
    const permission = usePermission();
    console.log("permission", permission);

    const [permissionGroups, setPermissionGroups] = useState([]);

    const getPermissionGroups = async () => {
        try {
            const res = await httpService.get(`${apis.permission.groups}/?page=${1}&take=${100}`);

            if (res.data?.result?.length) {
                setPermissionGroups(res.data.result);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getPermissionGroups();
    }, [isModalOpen]);

    return (
        <div>
            <div id="manage">
                <div id="group-list">
                    <div className="header">
                        <div className="header-name">
                            <p>{t("content.resource.manage.groupList")}</p>
                        </div>
                        <div className="header-btn">
                            <button className="btn-new" onClick={onClickNewGroupList}>
                                <PlusOutlined style={{ marginRight: "5px" }} />
                                {t("content.resource.manage.new")}
                            </button>
                        </div>
                    </div>
                    <div className="body">
                        <div className="container">
                            <div className="list">
                                {permissionGroups &&
                                    permissionGroups.map((i: any) => (
                                        <div
                                            key={i.id}
                                            className={`item ${activeIndex === i.id ? "active" : ""}`}
                                            onClick={() => handleItemClick(i.id)}
                                        >
                                            <div className="item-content">
                                                <div onClick={showModalCreate}>
                                                    <UserAddOutlined style={{ marginRight: "5px" }} />
                                                </div>
                                                <div>{i.name}</div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="available-permission">
                    <div className="header">
                        <div className="header-name">{t("content.resource.manage.activeResources")}</div>
                        <div className="header-btn">
                            <button className="btn-update_permission">
                                {t("content.resource.manage.updatePermission")}
                            </button>
                        </div>
                    </div>
                    <div className="body">
                        <div className="container">
                            <div className="list-permission">
                                <Collapse defaultActiveKey={["1"]} ghost items={items} />
                            </div>
                        </div>
                    </div>
                </div>
                <ModalNewGroupList isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            </div>

            <ModalCreateSubAccount isModalOpen={isModalCreateOpen} setIsModalOpen={setIsModalCreateOpen} />
        </div>
    );
};
