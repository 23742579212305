import { createSlice } from "@reduxjs/toolkit";

const promotionSlice = createSlice({
    name: "promotion",
    initialState: {
        currencyList: [],
        gameProviderList: [],
        languageList: [],
    },
    reducers: {
        setCurrencyList: (state, action) => {
            state.currencyList = action.payload;
        },
        setGameProviderList: (state, action) => {
            state.gameProviderList = action.payload;
        },
        setLanguageList: (state, action) => {
            state.languageList = action.payload;
        },
    },
});

export const { setCurrencyList, setGameProviderList, setLanguageList } =
    promotionSlice.actions;

export default promotionSlice.reducer;
