export const apis = {
    auth: {
        login: "/v1/auth/sign-in",
        register: "/v1/auth/sign-up",
        refreshToken: "/v1/auth/refresh-token",
    },
    user: {
        me: "/v1/users/me",
        list: "/v1/admin/users",
        // list: "/v1/users",
        detail: "/v1/admin/users/:id",
        update: "/v1/admin/users/:id",
        active: "/v1/admin/users/:id/active",
        detailWithParam: "/v1/admin/users/detail",
        manualTransfer: "/v1/admin/payment/manual-transfer",
    },
    bank: {
        bank: "/v1/admin/banks",
        addBankAccount: "/v1/bank-accounts",
        currency: "v1/bank-accounts/currency",
    },
    txn: {
        list: "/v1/admin/transactions",
    },
    promotion: {
        create: "/v1/admin/promotion",
        promotionRecord: "/v1/admin/promotion/promotion-record",
        promotionStatistics: "/v1/admin/promotion/promotion-statistic",
        promotionVerify: "/v1/admin/promotion/promotion-verify",
        userPromotion: "/v1/admin/promotion/user-promotion",
    },
    media: {
        imageUpload: "/image-upload",
    },
    gameAndProvider: {
        list: "/game-568-adapter/game-providers",
        gameType: "/game-568-adapter/game-provider-array",
        category: "/game-568-adapter/game-categories",
        betDetail: "/game-568-adapter/bet-payload",
    },
    webAdmin: {
        adminControlList: "/v1/admin/admin-controls",
        themes: "/v1/admin/customize-theme",
        domainThemes: "/v1/admin/customize-theme/domain",
        copyTheme: "/v1/admin/customize-theme/copy-theme",
    },

    report: {
        winLost: "/game-568-adapter/report/bet",
        revenueStatement: "/game-568-adapter/report/revenue",
        bettingRecord: "/game-568-adapter/report/bet-history",
        daily: "/game-568-adapter/report/daily",
        playerSummary: "/game-568-adapter/report/player-summary",
        winLostByPlayer: "/game-568-adapter/report/win-lost-by-player",
        winLostByPlayerPage: "/game-568-adapter/report/win-lost-by-player-page",
    },
    company: {
        language: "/v1/admin/language",
        currency: "/v1/admin/currency",
    },
    security: {
        changeLoginName: "/v1/admin/users/change-username",
        changePassword: "/v1/admin/users/change-password",
    },
    public: {
        languages: "/v1/public/languages",
    },
    home: {
        totalUser: "/v1/admin/users/total-users",
        userOnlinePast24h: "/v1/admin/user-tracking/online-past-24h",
        registerAndFirstDeposit24h: "/v1/admin/user-tracking/register-and-first-deposit",
        depositAndWithdraw24h: "/v1/admin/payment/deposit-and-withdraw-past-24h",
        bankChange7Days: "/v1/admin/payment/statistic-last-7-days",
    },
    permission: {
        groups: "/v1/admin/roles",
    },
    subAccount: {
        base: "/v1/admin/sub-account",
    },
};
