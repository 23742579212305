import { apis } from "../../apis"
import { httpService } from "../../apis/httpService"

export const getEnvironment = (): "browser" | "server" => {
    const isDOM =
        typeof window !== "undefined" && window.document && window.document.documentElement

    return isDOM ? "browser" : "server"
}

export const sleep = (timeoutMs: number) => {
    return new Promise((r) => {
        setTimeout(() => {
            r(true)
        }, timeoutMs)
    })
}

export const getCurrencyList = async () => {
    return httpService.get(apis.company.currency)
}

export const getGameProviders = async () => {
    return httpService.get(apis.gameAndProvider.list)
}
