import { useTranslation } from "react-i18next";
import { Button, Form, Input, Modal, Radio } from "antd";
import { useLayoutEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ModalEditSubAccount.scss";

type FieldType = {
  isModalOpen: boolean;
  setIsModalOpen: (value: any) => void;
  data: any;
};

const ModalEditSubAccount = ({
  setIsModalOpen,
  isModalOpen,
  data,
}: FieldType) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);

    toast.success("Success!");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useLayoutEffect(() => {
    if (isModalOpen) form.setFieldsValue(data);
  }, [isModalOpen]);

  return (
    <div className="modal-edit-sub-account__container">
      <Modal
        title={t("content.subAccountList.editSubAccountModal.title")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={null}
        className="modal-edit-sub-account"
      >
        <div style={{ marginTop: 20 }}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            style={{ textAlign: "center" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
          >
            <Form.Item
              label={t("content.subAccountList.editSubAccountModal.firstName")}
              name="fullName"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("content.subAccountList.editSubAccountModal.lastName")}
              name="fullName"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("content.subAccountList.editSubAccountModal.password")}
              name="password"
            >
              <Input.Password placeholder="********" />
            </Form.Item>
            <p className="change-password">
              {t(
                "content.subAccountList.editSubAccountModal.changePasswordNotice"
              )}
            </p>

            <Form.Item
              label={t("content.subAccountList.editSubAccountModal.phone")}
              name="phone"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t(
                "content.subAccountList.editSubAccountModal.status.title"
              )}
            >
              <Radio.Group defaultValue={data?.status}>
                <Radio value="Open">
                  {t(
                    "content.subAccountList.editSubAccountModal.status.type.open"
                  )}
                </Radio>
                <Radio value="Close">
                  {t(
                    "content.subAccountList.editSubAccountModal.status.type.close"
                  )}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t(
                "content.subAccountList.editSubAccountModal.suspend.title"
              )}
              name="Suspend"
            >
              <Radio.Group
                style={{ marginRight: 34 }}
                defaultValue={data?.suspend}
              >
                <Radio value="Yes">
                  {t(
                    "content.subAccountList.editSubAccountModal.suspend.type.yes"
                  )}
                </Radio>
                <Radio value="No">
                  {t(
                    "content.subAccountList.editSubAccountModal.suspend.type.no"
                  )}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t("content.subAccountList.editSubAccountModal.lock.title")}
            >
              <Radio.Group defaultValue={data?.lock}>
                <Radio value="Lock">
                  {t(
                    "content.subAccountList.editSubAccountModal.lock.type.lock"
                  )}
                </Radio>
                <Radio value="Unlock">
                  {t(
                    "content.subAccountList.editSubAccountModal.lock.type.unLock"
                  )}
                </Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              wrapperCol={{ offset: 8, span: 8 }}
              style={{ marginTop: 40 }}
            >
              <Button type="primary" htmlType="submit" onClick={handleOk}>
                {t("content.subAccountList.editSubAccountModal.save")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <ToastContainer autoClose={1500} />
    </div>
  );
};

export default ModalEditSubAccount;
